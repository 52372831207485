<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />

    <v-dialog v-model="suspendDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >Do you want to suspend this user ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="suspendUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="suspendDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="activateDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
            >
              Do you want to activate this user ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="activateUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="activateDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm2 text-center align-self-center>
              <v-avatar color="#FFF9C4" size="70">
                <v-img v-if="storage.fullName" :src="mediaURL + storage.photo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img v-else :src="mediaURL + 'noimage.jpg'">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-flex>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.fullName }}
                      <span v-if="!storage.fullName">-</span>
                    </span>
                  </v-flex>
                </v-flex>

                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Phone</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.phone }}
                      <span v-if="!storage.phone">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Email</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.email }}
                      <span v-if="!storage.email">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Address</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.address }}
                      <span v-if="!storage.address">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">District</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.district }}
                      <span v-if="!storage.district">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6  sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">City</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.city }}
                      <span v-if="!storage.city">-</span>
                    </span>
                  </v-flex>
                </v-flex>

                <v-flex xs6 sm3>
                  <v-flex xs12 text-left>
                    <span class="itemKey">State</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.state }}
                      <span v-if="!storage.state">-</span>
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex v-if="isRequired" xs12 sm12 xl12>
              <v-layout wrap justify-center py-2 py-xl-2>
                <v-flex xs6 sm2 xl2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Admin/public/reports?id='+storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View Reports </span>
                  </v-btn>
                </v-flex>
                <v-flex
                  v-if="storage.status == 'active'"
                  xs6
                  sm2
                  xl2
                  px-2
                  pt-2
                  pt-sm-0
                  pt-xl-0
                >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="suspendDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Suspend </span>
                  </v-btn>
                </v-flex>
                <v-flex
                  v-if="storage.status == 'inactive'"
                  xs6
                  sm2
                  xl2
                  px-2
                  pt-2
                  pt-sm-0
                  pt-xl-0
                >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#1885f2"
                    :ripple="false"
                    @click="activateDialogue = true"
                    class="itemValue"
                  >
                    <span style=" color:#1885f2"> Activate </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex> -->
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage","isRequired"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      suspendDialogue: false,
      banDialogue: false,
      activateDialogue: false,
    };
  },
  methods: {
    editUser() {
      this.$emit("stepper", {
        type: "Edit",
        editUser: true,
        userData: this.storage,
      });
    },
    suspendUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/suspend",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.suspendDialogue = false;
            this.$emit("stepper", {
              type: "Suspend",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    activateUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/activate",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.activateDialogue = false;
            this.$emit("stepper", {
              type: "Activate",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>